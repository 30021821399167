.map-block-modal {
  width: 60rem;

  &__body {
    min-height: 44.666rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__configs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x4);

    .selectv2__container {
      width: 100%;
    }
  }

  &__address-container {
    width: 100%;
    padding: var(--spacing-unit-x4) var(--spacing-unit-x4) 0;
  }

  &__menu-action-button {
    padding: var(--spacing-unit) 0;
  }

  &__select-popover {
    &__trigger {
      &:focus-visible {
        border: var(--standard-border-dark-blue);
      }
    }

    &-selected {
      display: flex;
      background-color: var(--white);
      gap: var(--spacing-unit-x3);

      &-icon {
        border: var(--standard-border);
        border-radius: var(--standard-border-radius);
      }
    }

    &__anchor {
      display: flex;
      background-color: var(--white);
      justify-content: space-between;
      padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);

      & + div {
        width: 56rem;
      }
    }

    & .button--outline {
      gap: var(--spacing-unit);
      margin: var(--spacing-unit-x2) var(--spacing-unit-x4);
      width: calc(100% - var(--spacing-unit-x8));
    }

    & .svg {
      margin: 0;
    }
  }

  &__no-address-selected {
    width: 100%;
    height: 33.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--page-back);
  }

  &__error {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);
    margin-top: var(--spacing-unit);
  }

  .modal-body {
    padding: 0;
  }
}
