.asset-picker-preview {
  background-color: var(--asset-picker-preview-background);
  width: 100%;

  .spinner {
    align-self: center;
  }

  &--loading {
    background-color: var(--white);
  }

  &--has-metadata {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
  }

  &__custom-content {
    background-color: var(--page-back);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    background-color: var(--white);
    margin: 0 var(--spacing-unit-x7) 0 var(--spacing-unit-x7);
    padding: var(--spacing-unit-x7);
    height: 100%;
    width: calc(100% - var(--spacing-unit-x14));
  }
}
