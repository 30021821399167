.asset-picker-sidebar {
  &__section-loader {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__custom-filter {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: var(--spacing-unit);

    &__name {
      flex-grow: 1;
    }

    &__count {
      justify-content: right;
      padding-right: var(--spacing-unit-x3);
    }
  }

  .menu {
    &:not(:first-of-type) {
      padding-top: var(--spacing-unit);
    }

    border-bottom: none;
    border-left: none;
    border-top: none;
    border-radius: unset;
    flex-shrink: 0;
    padding: 0 0 0 0;

    .tag {
      padding-left: 2.1rem;
    }

    .sortable-folders {
      padding-bottom: 0.6rem;
    }

    .folder {
      padding: 0 1.8rem 0 2.1rem;
    }

    .filter__count {
      padding-right: 0;
    }

    .selectv2 {
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4) 0 var(--spacing-unit-x4);
    }

    .menu__items {
      height: 100%;
    }

    &__item {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      width: 100%;

      /** This is a hack that allows the ::after pseudo-element to be displayed */
      border-bottom: 0.0833rem solid var(--white);

      .menu-item-with-header__header-text {
        gap: var(--spacing-unit-x2);
      }

      &:first-of-type {
        padding-top: var(--spacing-unit);
      }

      &::after {
        display: block;
        margin: 0 var(--spacing-unit-x3) 0 var(--spacing-unit-x3);
        border-bottom: var(--standard-border);
        width: 22rem;
        content: '';
      }

      &:last-child::after,
      &:has(.menu-item__select)::after {
        content: unset;
      }

      &__select {
        border-bottom: none;

        &::after {
          content: unset;
        }
      }

      & .filter:last-of-type {
        margin-bottom: var(--spacing-unit);
      }

      & .menu-item__select {
        margin: 0 0 var(--spacing-unit) 0;
      }

      & .menu-item > .radiogroup,
      & .menu-item > .checkboxgroup {
        padding: 0 0 var(--spacing-unit-x2) var(--spacing-unit-x4);
      }

      .radio__label {
        padding-left: 2.3rem;
      }

      .checkboxgroup__container {
        gap: var(--spacing-unit-x2);
      }

      .checkbox__container,
      .radio__container {
        gap: var(--spacing-unit);
        line-height: var(--line-height-large);
      }

      .menu-item-with-header__header {
        padding-left: var(--spacing-unit-x4);

        &-open {
          padding-bottom: var(--spacing-unit);
        }
      }
    }
  }

  &__info-block {
    position: absolute;
    bottom: 0;
    background-color: var(--input-background);
    border-radius: var(--standard-border-radius);
    margin: auto var(--spacing-unit-x4) var(--spacing-unit-x4);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  }
}