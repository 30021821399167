.email-picker-modal {
  &__item-name {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);
  }

  &__item-name-typography {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: var(--half-spacing-unit);

    &__secondary-text-wrapper {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      align-items: center;
      gap: var(--spacing-unit);
    }
  }
}