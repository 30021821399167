.asset-picker-preview-metadata {
  min-width: 25rem;
  max-width: 25rem;
  border-left: var(--standard-border);
  padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  background-color: var(--white);

  &__header {
    padding-bottom: var(--spacing-unit-x4);
    border-bottom: var(--standard-border);

    .typography {
      overflow: hidden;
      /* stylelint-disable */
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      /* stylelint-enable */
    }
  }

  &__properties {
    display: flex;
    flex-direction: row;

    &__container {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x4);
      padding: var(--spacing-unit-x4) 0;
    }

    &__col {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
      flex-basis: 50%;
    }

    &__edit-button {
      width: max-content;
      display: flex;
      gap: 0.833rem;
      padding: var(--spacing-unit) var(--spacing-unit-x3);
    }
  }
}
