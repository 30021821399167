.modal-footer-v2 {
  display: flex;

  &__form,
  &__list {
    border-top: var(--standard-border);
    border-top-color: var(--light-theme-border);
  }

  &__default &__inner {
    padding: var(--spacing-unit-x4);
  }

  &__inner {
    flex: 1 1 100%;
    position: relative;
    padding: var(--spacing-unit-x4);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-show-top-border {
      border-top: var(--standard-border);
    }

    &-left {
      flex: 1 1 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: var(--spacing-unit-x2);
    }
  }

  &__cancel-button {
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;

      &:hover {
        color: var(--light-gray);
      }
    }
  }

  &__action-button-loading {
    .loader {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);

      svg {
        width: var(--spacing-unit-x4);
        height: var(--spacing-unit-x4);
      }
    }
  }

  &__view-selected {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);

    &-button {
      gap: calc(var(--spacing-unit-x5) / 3);
    }
  }

  &__delete {
    display: flex;
    gap: var(--spacing-unit);
  }

  &__legend {
    display: flex;
    gap: var(--spacing-unit-x2);
    align-items: center;

    &-item {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }
  }
}
