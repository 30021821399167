.sms-picker-preview {
  background-color: var(--page-back);

  .mobile-view__content {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .container__wrapper.text-bubble {
    width: 21.25rem;
    padding: unset;
    height: fit-content;
    margin-top: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x4);
  }
}
